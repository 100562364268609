import axios from 'axios';
axios.defaults.withXSRFToken = true;
axios.defaults.xsrfHeaderName = "X-CSRF-TOKEN";

export default {

   async getUserTalant(context, code) {
    try {

      const apiUrl = '/api/user/getUserTalant'; // Замените на ваш URL API
      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
      const data = "code="+code;

      const response = await axios.get(apiUrl+"?"+data,{headers});


      const talantInfo = response.data;

      let talantObj = talantInfo.outIO.ListOfUserTalant.UserTalant[0];
      let oldTalant = talantInfo.outIO.ListOfUserTalant.UserTalant[0];
      if (typeof talantObj === undefined) talantObj = {};

      context.commit('SET_TALANT', talantObj);
      let talantTools = [];
      if (talantObj.ListOfTool !== undefined)
        talantTools = talantObj.ListOfTool.Tool;
      context.commit('SET_TALANTTOOLS', talantTools);
      let talantStyles = [];
      if (talantObj.ListOfStyle !== undefined)
        talantStyles = talantObj.ListOfStyle.Style;
      context.commit('SET_TALANTSTYLES', talantStyles);
    } catch (error) {
      context.commit('SET_TALANT', {});
      context.commit('SET_TALANTTOOLS', []);
      context.commit('SET_TALANTSTYLES', []);
    }
  },
  async updateTalant(context, code){
    try {

      const apiUrl = '/api/user/syncTalant'; // Замените на ваш URL API

      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
      let tools = {};
      if (context.getters.talantTools.length>0)
        tools = {
          "Tool": context.getters.talantTools.map(obj => ({Code: obj.Code}))
        }
      let styles = {};
      if (context.getters.talantStyles.length>0)
        styles = {
          "Style": context.getters.talantStyles.map(obj => ({Code: obj.Code}))
        }

     const  data = {
        "InIO": {
          "IntObjectName": "UserTalant",
          "ListOfUserTalant": {
            "UserTalant": [
              {
                "Code": context.getters.Talant.Code,
                "CreativeName": context.getters.Talant.CreativeName,
                "About": context.getters.Talant.About,
                "ListOfTool": tools,
                "ListOfStyle": styles,
              }
            ]
          }
        }
      };



      const response = await axios.post(apiUrl, data, {headers});

      const userInfo = response.data;

    //  let userObj = userInfo.outIO.ListOfUserInfo.UserInfo[0];
   //   context.commit('SET_CURRENT_USER', userObj);
      context.dispatch('showMessage', {type: "success", message: "Данные таланта успешно обновлены"});

    } catch (error) {
        context.dispatch('showMessage', {type: "error", message: "Ошибка обновления таланта пользователя"});
        console.error('Ошибка обновления таланта пользователя:', error.message);
      throw error;
    }
  }

}
