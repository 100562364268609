import axios from 'axios';

const currentVersion = process.env.VUE_APP_VERSION;

console.log('currentVersion', currentVersion);

axios.interceptors.response.use(
  (response) => {
    const storedVersion = localStorage.getItem('currentVersion');

    if (currentVersion && currentVersion !== storedVersion && response.config.method === 'get') {
      localStorage.setItem('currentVersion', currentVersion);
      window.location.reload();
    }

    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;

