<template>
  <router-view />
</template>
<script>
export default {
  name: "App",
  mounted() {
 //   console.log("Session");
    this.$store.dispatch("getSessionInfo");
  },
};
</script>

<style lang="scss">
@import "./assets/scss/socialv.scss";
@import "./assets/scss/customizer.scss";
@import url("./plugins/Leaflet/leaflet.css");
@import url("./plugins/fullcalendar/core/main.css");
@import url("./plugins/fullcalendar/daygrid/main.css");
@import url("./plugins/fullcalendar/timegrid/main.css");
@import url("./plugins/fullcalendar/list/main.css");
@import "bootstrap/dist/css/bootstrap.min.css";

@font-face {
  font-family: "Montserrat Alternates";
  src: url('./assets/fonts/Montserrat_Alternates/MontserratAlternates-Regular.ttf');
}
@font-face {
  font-family: "Montserrat Alternates Bold";
  src: url('./assets/fonts/Montserrat_Alternates/MontserratAlternates-Bold.ttf');
}
@font-face {
  font-family: "Montserrat Alternates Italic";
  src: url('./assets/fonts/Montserrat_Alternates/MontserratAlternates-Italic.ttf');
}

</style>
