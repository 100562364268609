//import getters from './getters'
import actions from './actions'
//import mutations from './mutations'

const state = {
  filterTTypeTxt: '',
  filterTStyleTxt: '',
  Talant: {},
  talantTools: [],
  _talantTools: [],
  talantStyles: [],
  _talantStyles: []
 }
const getters = {
  filterTTypeTxt: state => state.filterTTypeTxt,
  filterTStyleTxt: state => state.filterTStyleTxt,
  Talant: state => state.Talant,
  talantTools: state => {
    if (state.filterTTypeTxt !== '')
      return state.talantTools.filter(item => item.Name.toLowerCase().includes(state.filterTTypeTxt.toLowerCase())).sort((a, b) => a.Name.localeCompare(b.Name));
    else
      return state.talantTools;
  },
  talantStyles: state => {
    if (state.filterTStyleTxt !== '')
      return state.talantStyles.filter(item => item.Name.toLowerCase().includes(state.filterTStyleTxt.toLowerCase())).sort((a, b) => a.Name.localeCompare(b.Name));
    else
      return state.talantStyles;
  }
}

const mutations = {

  SET_TALANT(state, talant) {
    state.Talant = talant;
  },
  UpdateCreativeName(state, value) {
    state.Talant.CreativeName = value;
  },
  UpdateAboutTalant(state, value) {
    state.Talant.About = value;
  },
  SET_TALANTTOOLS(state, tools) {
    state.talantTools = tools;
  },
  SET_TALANTSTYLES(state, styles) {
    state.talantStyles = styles;
  },
  PUSH_TALANTTOOL(state, tool) {
    if(state.talantTools.findIndex(item => item.Code === tool.Code) === -1)
     state.talantTools.push(tool);
  },
  PUSH_TALANTSTYLE(state, style) {
    if(state.talantStyles.findIndex(item => item.Code === style.Code) === -1)
      state.talantStyles.push(style);
  },
  DEL_TALANTTOOL(state, code) {
    let index = state.talantTools.findIndex(item => item.Code === code);
    if(index !== -1)
      state.talantTools.splice(index,1);
  },
  DEL_TALANTSTYLE(state, code) {
    let index = state.talantStyles.findIndex(item => item.Code === code);
    if(index !== -1)
      state.talantStyles.splice(index,1);
  },
  SET_TTYPETXTFILTER(state, filter) {
    state.filterTTypeTxt = filter;
  },
  SET_TSTYLETXTFILTER(state, filter) {
    state.filterTStyleTxt = filter;
  },
}
export default {
 // namespaced: true,
  state,
  getters,
  actions,
  mutations
}
