import en from "@vueform/vueform/locales/en";
import bootstrap from "@vueform/vueform/dist/bootstrap";
import { defineConfig } from "@vueform/vueform";
import PluginMask from '@vueform/plugin-mask'

export default defineConfig({
  theme: bootstrap,
  locales: { en },
  locale: "en",
  plugins: [
    PluginMask,
  ]
});
