import axios from "axios";


axios.defaults.withXSRFToken = true;
axios.defaults.xsrfHeaderName = "X-CSRF-TOKEN";

export default {
  async getFilesUser({ commit }, type) {
    commit('SET_LOADER', true);
    try {
      const apiUrl = "/api/user/getUserFileList"; 
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const data = "includeDeleted=false&onlyDeleted=false&type=" + type;

      const response = await axios.get(apiUrl + "?" + data, { headers });
      console.log('response.data', response.data);
      const fileList = response.data.outIO?.ListOfUserFileList?.Files;    
      console.log('FilesUser', fileList)
      if (type === 'AUDIO') {
        commit('SET_USER_AUDIO', fileList)
      }
      if (type === 'VIDEO') {
        commit('SET_USER_VIDEO', fileList)
      }
      
    } catch (error) {
      console.error("Ошибка при получении файлов:", error);
      throw error;
      
    }finally{
    commit('SET_LOADER', false);
    }
  },


  async getUserToolList(context) {
    try {
      
      const apiUrl = "/api/user/getUserToolList"; // Замените на ваш URL API
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const response = await axios.get(apiUrl, { headers });

      const userTools = response.data;
      let toolsObj = userTools.outIO.ListOfUserToolList;
      if (toolsObj === undefined) toolsObj = [];
      context.commit("SET_CURRENT_USER_TOOLS", toolsObj.Tools);
    } catch (error) {
      console.error(error);
      context.commit("SET_CURRENT_USER_TOOLS", []);
    }
  },
  async getUserStyleList(context) {
    try {
      const apiUrl = "/api/user/getUserStyleList"; // Замените на ваш URL API
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const response = await axios.get(apiUrl, { headers });

      const userStyles = response.data;
      let stylesObj = userStyles.outIO.ListOfUserStyleList;
      if (stylesObj === undefined) stylesObj = [];
      context.commit("SET_CURRENT_USER_STYLES", stylesObj.Styles);
    } catch (error) {
      console.error(error);
      context.commit("SET_CURRENT_USER_STYLES", []);
    }
  },
  async getUserTalantList(context) {
    try {
      const apiUrl = "/api/user/getUserTalantList"; // Замените на ваш URL API
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const response = await axios.get(apiUrl, { headers });

      const userTalants = response.data;
      let talantsObj = userTalants.outIO.ListOfUserTalantList;
      if (talantsObj === undefined) talantsObj = [];
      context.commit("SET_CURRENT_USER_TALANTS", talantsObj.UserTalant);
    } catch (error) {
      console.error(error);
      context.commit("SET_CURRENT_USER_TALANTS", []);
    }
  },
  async getUserFileList(context, data) {
    await context.commit("SET_USER_AUDIO", data);
  },
  async addFile(context, newFile) {
    let fileData = {
      fileType: newFile.type,
      externalFlag: newFile.externalFlag,
      hostingService: newFile.hostingService,
      originName: newFile.originName,
    };
    if (newFile.externalFlag === "N") {
      let formData = new FormData();
      formData.append("file", newFile.file);

      await axios
        .post("/api/storage", formData)
        .then((response) => {
          context.dispatch("showMessage", {
            type: "success",
            message: "Изображение загружено на сервер",
          });
          fileData["fileName"] = response.data.fileName;
          fileData["fileURL"] = response.data.url;
          fileData["fileExt"] = response.data.ext;
          fileData["fileSize"] = response.data.size;
        })
        .catch((error) => {
          context.dispatch("showMessage", {
            type: "error",
            message: "Ошибка загрузки изображения",
          });
          console.error(error);
        });
    }

    const apiUrl = "/api/user/addUserFile"; // Замените на ваш URL API
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return await axios
      .post(apiUrl, fileData, { headers })
      .then((response) => {
        if (newFile.setAsAvatar) {
          const avatar = {
            photoId: response.data.fileId,
          };
          context.dispatch("setUserAvatar", avatar);
        } else return response.data.fileId;
      })
      .catch((error) => {
        context.dispatch("showMessage", {
          type: "error",
          message: "Ошибка отправки данных аватара",
        });
        console.error(error);
      });
  },

  
  async addVideoLink({ commit }, data) {
    try {
      const response = await axios.post('/api/user/addUserFile',
         data , {
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      console.log('response.data', response.data)
      return  response.data
    } catch (error) {
      console.error('Error uploading video link:', error);
      throw error;
    }
  },
  async setUserAvatar(context, data) {
    try {
      const apiUrl = "/api/user/setUserAvatar"; // Замените на ваш URL API
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      await axios.post(apiUrl, data, { headers });
      context.dispatch("showMessage", {
        type: "success",
        message: "Аватар успешно обновлен",
      });
    } catch (error) {
      context.dispatch("showMessage", {
        type: "error",
        message: "Ошибка обновления аватара пользователя",
      });
      console.error(error);
    }
  },
  async addUserTalant(context, code) {
    try {
      const apiUrl = "/api/user/addUserTalant"; // Замените на ваш URL API
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const data = {
        talantCode: code,
      };

      const response = await axios.post(apiUrl, data, { headers });

      const userInfo = response.data;
      //   console.log(userInfo);
      let userObj = userInfo.outIO.ListOfUserInfo.UserInfo[0];
      let userTalants = {};
      if (typeof userObj !== undefined) {
        if (typeof userObj.ListOfTalants !== undefined) {
          userTalants = userObj.ListOfTalants.Talants;
        }
      }
      context.commit("SET_CURRENT_USER_TALANTS", userTalants);
      await context.dispatch("getUserAvailableTalantList");
    } catch (error) {
      console.error(error);
    }
  },
  async getUserAvailableTalantList(context) {
    try {
      const apiUrl = "/api/user/getUserAvailableTalantList"; // Замените на ваш URL API
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const data = "StartRowNum=0&PageSize=100&languageCode=ru";

      const response = await axios.get(apiUrl + "?" + data, { headers });

      const userAvailableTalants = response.data;
      let talantObj = userAvailableTalants.outIO.ListOfTalantList;
      if (talantObj === undefined) talantObj = { Talants: [] };
      context.commit("SET_CURRENT_USER_AVAILABLE_TALANTS", talantObj);
    } catch (error) {
      console.error(error);
      context.commit("SET_CURRENT_USER_AVAILABLE_TALANTS", {
        ListOfTalants: { Talants: [] },
      });
    }
  },
  async confirm(context, { code, user, pass }) {
    context.commit('SET_LOADER', true);
    console.log("Confirm: ", code, user, pass);
    try {
      const response = await axios.post("/registration/confirm?otp="+code, { withCredentials: true });
      console.log(response);
      await context.dispatch("login", { user, pass });
    } catch (error) {
       // Более подробная информация об ошибке
       console.error("Error details:", error.response?.data || error.message);
       console.error("Response status:", error.response?.status);
       console.error("Response headers:", error.response?.headers);
      console.error("Ошибка подтверждения проверочного кода:", error.message);
      throw error;
    }finally{
      context.commit('SET_LOADER', false);
    }
  },
  async register(context, data) {
    context.commit('SET_LOADER', true);
    try {
      const res = await axios.post("/registration/init", data,{withCredentials: true});
      console.log("Register res:", res);
      context.commit("SET_REGCONFIRM", true);
     
      context.commit("SET_REGDATA", data);
    } catch (error) {
      context.dispatch("showMessage", {
        type: "error",
        message: error.response.data.message,
      });
      console.log(error)
    }finally{
      context.commit('SET_LOADER', false);
    }
  },

  async resendConfirmationCode(context) { 
    try {
      const res = await axios.get("/registration/renewcode", { withCredentials: true });
      console.log("Renewcode response:", res);
    } catch (error) {
      // Более подробная информация об ошибке
      console.error("Error details:", error.response?.data || error.message);
      console.error("Response status:", error.response?.status);
      console.error("Response headers:", error.response?.headers);
    } 
  },
  
  async login(context, { user, pass }) {
    context.commit('SET_LOADER', true);
    const form = new FormData();
    form.append("username", user);
    form.append("password", pass);
    await axios
      .post("/login", form)
      .then((response) => {
        context.commit("SET_LOGIN", true);
        context.dispatch("getUserInfo");
        context.dispatch("getUserAvailableTalantList");
       
      })

      .catch((error) => {
        console.log("Response login: ", error);
        context.dispatch("showMessage", {
          type: "error",
          message: "Ошибка при входе в систему",
        });
      })
      .finally(() => {
        context.commit('SET_LOADER', false);
      });
  },
  async initResetPassword(context, email) {
    context.commit('SET_LOADER', true);
    try {
      const response = await axios.post(
        "/reset-password/init",
        { email },
        { withCredentials: true },
      );
      context.commit("SET_SHOW_INPUT_RESET_CODE", true);
    } catch (error) {
      console.error("Ошибка запроса:", error);
      context.dispatch("showMessage", {
        type: "error",
        message: "Не верный Email",
      });
    }finally {
      context.commit('SET_LOADER', false);
    }
  },
  async sendConfirmCode(context, confirmCode) {
    context.commit('SET_LOADER', true);
    try {
      const response = await axios.post(
        "/reset-password/confirm",
        {
          otp: confirmCode,
        },
        {
          withCredentials: true,
        },
      );
      console.log("Ответ сервера:", response.data);
      await context.dispatch("showMessage", {
        type: "success",
        message:
          "Верный код, перейдите по ссылке которая прийдет на почту и установите новый пароль.",
      });
    } catch (error) {
      console.error("Ошибка запроса:", error);
      await context.dispatch("showMessage", {
        type: "error",
        message: "Не верный код подтверждения",
      });
    }finally {
      context.commit('SET_LOADER', false);
    }
  },

  async getUserInfo(context) {
    try {
      const apiUrl = "/api/user/userInfo "; // Замените на ваш URL API
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
  
      const response = await axios.get(apiUrl, { headers });
      
      const userInfo = response.data;
    
      let userObj = userInfo.outIO.ListOfUserInfo.UserInfo[0];
    

      let userTalants = {};
      if (typeof userObj === "undefined") {
        userObj = {};
        console.log("userObj is undefined, setting to empty object."); // Если userObj undefined
      } else {
       
        if (typeof userObj.ListOfTalants !== "undefined") {
          userTalants = userObj.ListOfTalants.Talants;
         
        } else {
          console.log("ListOfTalants is undefined."); // Если таланты не определены
        }
      }
      
      context.commit("SET_CURRENT_USER", userObj);
      context.commit("SET_CURRENT_USER_TALANTS", userTalants);
     
    } catch (error) {
      context.commit("SET_CURRENT_USER", {});
     
    }
  },
  async logout(context) {
    try {
      const response = await axios.post("/logout");
      console.log(response);
      context.commit("SET_LOGIN", false);
      context.commit("SET_CURRENT_USER", {});
    } catch (error) {
      context.dispatch("showMessage", {
        type: "error",
        message: "Ошибка при выходе из системы",
      });
      console.error("Ошибка при выходе:", error.message);
      throw error;
    }
  },
  async updateUserInfo(context, data) {
    try {
      // const apiUrl = '/workflow/updateUserInfo'; // Замените на ваш URL API
      const apiUrl = "/api/user/updateUserInfo"; // Замените на ваш URL API

      const username = "Master"; // Замените на ваше имя пользователя
      const password = "ujybim"; // Замените на ваш пароль

      const auth = "Basic " + btoa(username + ":" + password);
      const headers = {
        //           'Authorization': auth,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      const response = await axios.post(apiUrl, data, { headers });
      console.log(response);
      const userInfo = response.data;
      let userObj = userInfo.outIO.ListOfUserInfo.UserInfo[0];
      await context.commit("SET_CURRENT_USER", userObj);
      await context.dispatch("showMessage", {
        type: "success",
        message: "Данные пользователя успешно сохранены",
      });
    } catch (error) {
      await context.dispatch("showMessage", {
        type: "error",
        message: "Ошибка сохранения данных пользователя",
      });
      console.error(
        "Ошибка получения информации о пользователе:",
        error.message,
      );
    }
  },
};
