import axios from "axios";
axios.defaults.withXSRFToken = true;
axios.defaults.xsrfHeaderName = "X-CSRF-TOKEN";

const state = {
  searchResponse:JSON.parse(localStorage.getItem('usersData')) ||[],
  noResult: false,
  searchAudio: {},
  showProfiles: false,
  showAudio:false


};
const getters = {
  searchResponse: (state) => state.searchResponse,
  searchAudio: (state) => state.searchAudio,
  noResult: (state) => state.noResult,
  showProfiles: state => state.showProfiles,
  showAudio: state => state.showAudio,
};
const mutations = {
  updateSearchResponse(state, responseData) {
    state.searchResponse = responseData;
  },
  SET_SEARCH_AUDIO(state, responseData) {
    state.searchAudio = responseData;
  },
  NO_RESULT(state, value) {
    state.noResult = value;
  },
  SET_SHOW_PROFILES(state, value) {
    state.showProfiles = value;
  },
  SET_SHOW_AUDIO(state, value) {
    state.showAudio = value;
  },
};
const actions = {
  async sendSearchRequest({ commit }, requestData) {
    commit('NO_RESULT', false);
    commit('SET_LOADER', true);
    try {
      axios.defaults.withCredentials = true;
      const response = await axios.post("api/search", requestData, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (response.data.rowNum==='0') {
        commit('NO_RESULT', true);
      }

      commit("updateSearchResponse", response.data);
      commit('SET_SHOW_PROFILES', true);
      commit('SET_SHOW_AUDIO', false);
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      commit('SET_LOADER', false);
    }
  },
  async searchAudio({ commit }, requestData) {
    commit('NO_RESULT', false);
    commit('SET_LOADER', true);
    try {
      axios.defaults.withCredentials = true;

      const response = await axios.post("api/search/executeAudioSearch", requestData, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      console.log('response.data.rowNum', response.data.rowNum)
      if (response.data.rowNum==='0') {
        commit('NO_RESULT', true);
      }

      commit("SET_SEARCH_AUDIO", response.data);
      commit('SET_SHOW_PROFILES', false);
      commit('SET_SHOW_AUDIO', true);

      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      commit('SET_LOADER', false);
    }
  },
};

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations,

};
